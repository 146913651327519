import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "../../api/api";
import EventCard from "./EventCard";
import Mesa from "./Mesa";
import FormVenda from "./FomVenda";
import { getEvents } from "../../api/eventService";

const RealizarVendaContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5; /* Fundo suave para destacar conteúdo */
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020; /* Cor vinho para harmonia */
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase; /* Transformar o texto para maiúsculas */
  letter-spacing: 2px; /* Espaçamento entre letras */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombras sutis para destacar o texto */
  background: linear-gradient(90deg, rgba(128,0,32,1) 0%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  color: transparent; /* Efeito gradiente */
`;

const EventList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const MesaList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 20px;
`;

const EventDetails = styled.div`
  margin-top: 20px;
`;

const RealizarVendaAdmin = () => {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function fetchEvents() {
      const response = await getEvents();
      setEvents(response);
    }
    fetchEvents();
  }, []);

  return (
    <RealizarVendaContainer>
      <Title> Venda Administrador</Title>

      <FormVenda
        eventsDb={events}
      />
    </RealizarVendaContainer>
  );
};

export default RealizarVendaAdmin;
