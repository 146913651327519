import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 5px;
  
`;

const ModalContent = styled.div`
  background-color: #E6E6FA;
  padding: 20px;

  max-width: 200px;
  width: 20%;
  text-align: center;
  border-radius: 40px;
  &:hover {
    background: #00bfff;
    cursor: pointer;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
`;

const Modal = ({ children, onClose, onClick }) => {
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={onClick}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
