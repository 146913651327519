import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import Sidebar from './components/SideBar';
import { ThemeProvider } from 'styled-components';
import theme from './styles/Theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      {/* Envolva o Router ao redor de todo o conteúdo */}
      <Router>
        <Sidebar /> {/* Sidebar agora está dentro do Router */}
        <Routes />  {/* Routes também dentro do Router */}
      </Router>
    </ThemeProvider>
  );
};

export default App;
