import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../api/api";
import { apartamentos } from "../../utils/apartamentos";
import { isValidCPF } from "../../utils/cpfValidator";
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  MenuItem,
  Button as MUIButton,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import QRCodeInfo from "../../components/QRCodeInfo";
import EventCard from "./EventCard";
import { getEvents } from "../../api/eventService";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PixIcon from "@mui/icons-material/QrCode2";
import MoneyIcon from "@mui/icons-material/AttachMoney";

// Background estilizado
const PageContainer = styled.div`
  background-image: url("/path/to/your/background/image.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const EventList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const FormContainer = styled.div`
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
`;

const SectionTitle = styled.h3`
  color: #800020;
  margin-bottom: 20px;
`;

const steps = [
  "Escolher Evento",
  "Escolher Mesa",
  "Cadastro",
  "Finalizar Compra",
];

const PaymentSuccessModal = ({ open, onClose, imageSrc, ticketUrl }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Pagamento cartão Gerado!</DialogTitle>
      <DialogContent>
        <img src={imageSrc} alt="logo mercado pago" style={{ width: "100%" }} />
        <MUIButton
          variant="contained"
          onClick={() => window.open(ticketUrl, "_blank")}
        >
          Finalizar Pagamento no checkout do mercado pago
        </MUIButton>
      </DialogContent>
    </Dialog>
  );
};

const FormVenda = ({ eventsDb }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    cpf: "",
    apartamento: "",
    torre: "Acqua",
    perfil: "Morador",
  });
  const [qrCodeData, setQrCodeData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedMesas, setSelectedMesas] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [valorTotal, setValorTotal] = useState(0);
  const [eventos, setEventos] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState("Pix"); // Estado para forma de pagamento
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [urlMp, setUrlMp] = useState("");
  const [reponseData, setReponseData] = useState("");
  const [loadingAction, setloandingAction] = useState(false); // Define o número de vendas por página

  function confirmarAcao(message) {
    // eslint-disable-next-line no-restricted-globals
    return confirm(message);
  }

  async function fetchEvents() {
    const response = await getEvents();
    setEventos(response);
  }

  useEffect(() => {
    setEventos(eventsDb);
  }, [eventsDb]);

  const handleNext = () => {
    if (activeStep + 1 === 3) {
      calcularValorTotal();
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleMesaSelection = (mesa) => {
    if (selectedMesas.includes(mesa)) {
      setSelectedMesas(selectedMesas.filter((num) => num !== mesa));
    } else {
      setSelectedMesas([...selectedMesas, mesa]);
    }
  };

  const calcularValorTotal = (formaPagamento) => {
    setValorTotal(
      selectedEvent && selectedMesas.length > 0
        ? selectedMesas.length * selectedEvent.valor
        : 0
    );

    if (formaPagamento === "Cartão") {
      setValorTotal(valorTotal + valorTotal * 0.056);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidCPF(formData.cpf)) {
      setErrorMessage("CPF inválido");
      return;
    }

    // Caso seja Pix ou Dinheiro
    if (validateForm()) {
      const data = {
        ...formData,
        mesasCompradas: selectedMesas,
        idEvent: selectedEvent._id,
        formaPagamento,
        valorCompra: valorTotal,
        vendauser: false,
      };

      try {
        if (confirmarAcao("Dados e forma de pagamento estão corretos?")) {
          const response = await api.post("api/vendas/realizar-venda", data);

          console.log(response);

          if (formaPagamento === "Pix") {
            setQrCodeData(response.data);
            setSuccessMessage("Venda realizada com sucesso!");
            setModalOpen(true);
          } else if (formaPagamento === "Cartão") {
            setReponseData(response.data);
            if (response.data) {
              console.log(response.data);
              console.log(response.data.venda.preference);

              setUrlMp(response.data.venda.preference.init_point);
              setPaymentModalOpen(true);
            } else {
              setErrorMessage("Erro ao tertar gerar pgamento com cartão");
            }
          } else {
            setSuccessMessage("Venda realizada com sucesso!");
            setModalOpen(true);
          }
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          setErrorMessage(
            "Erro ao realizar venda: " + error.response.data.message
          );
        } else {
          setErrorMessage(
            "ocorreu um erro verifique a lista de vendas o status da venda."
          );
        }
      }
    }
  };

  const validateForm = () => {
    const { nome, email, cpf, apartamento } = formData;
    if (
      !nome.includes(" ") ||
      !isValidCPF(cpf) ||
      !email.includes("@") ||
      !apartamento
    ) {
      setErrorMessage(
        "Por favor, preencha todos os campos corretamente. O nome deve ser completo e o CPF deve ser sem . e -"
      );
      return false;
    }
    return true;
  };

  const hadleCloseModal = async () => {
    setModalOpen(false);
    setPaymentModalOpen(false);
    setQrCodeData(null);
    setSelectedMesas([]);
    setSelectedEvent(null);
    setValorTotal(0);
    setActiveStep(0);
    setUrlMp("");
    await fetchEvents();
  };

  return (
    <PageContainer>
      <FormContainer>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <>
              <SectionTitle>Escolher Evento</SectionTitle>
              <EventList>
                {eventos.map((evento) => (
                  <EventCard
                    key={evento._id}
                    event={evento}
                    selected={selectedEvent && selectedEvent._id === evento._id}
                    onClick={() => setSelectedEvent(evento)}
                  />
                ))}
              </EventList>

              <MUIButton
                onClick={handleNext}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={!selectedEvent}
              >
                Próximo
              </MUIButton>
            </>
          )}

          {activeStep === 1 && (
            <>
              <SectionTitle>Escolher Mesa</SectionTitle>
              {selectedEvent.mesas.map((mesa) => (
                <MUIButton
                  key={mesa}
                  variant="contained"
                  sx={{
                    backgroundColor: selectedMesas.includes(mesa)
                      ? "yellow"
                      : mesa.flagDisponivel
                      ? "green"
                      : "grey",
                    margin: 1,
                  }}
                  onClick={() =>
                    mesa.flagDisponivel && handleMesaSelection(mesa)
                  }
                  disabled={!mesa.flagDisponivel}
                >
                  Mesa {mesa.numero}
                </MUIButton>
              ))}
              <MUIButton
                onClick={handleNext}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                disabled={selectedMesas.length === 0}
              >
                Próximo
              </MUIButton>
              <MUIButton
                onClick={handleBack}
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{ mt: 2 }}
              >
                Voltar
              </MUIButton>
            </>
          )}

          {activeStep === 2 && (
            <>
              <SectionTitle>Cadastro</SectionTitle>
              <TextField
                label="Nome Completo"
                fullWidth
                value={formData.nome}
                onChange={(e) =>
                  setFormData({ ...formData, nome: e.target.value })
                }
                required
                margin="normal"
              />
              <TextField
                label="Email"
                fullWidth
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
                margin="normal"
              />
              <TextField
                label="CPF"
                fullWidth
                value={formData.cpf}
                type="number"
                onChange={(e) =>
                  setFormData({ ...formData, cpf: e.target.value })
                }
                required
                margin="normal"
              />
              <TextField
                placeholder="Telefone"
                value={formData.telefone}
                type="number"
                onChange={(e) =>
                  setFormData({ ...formData, telefone: e.target.value })
                }
                required
              />
              <TextField
                label="Senha"
                fullWidth
                type="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                required
                margin="normal"
              />
              <Select
                label="Torre"
                fullWidth
                value={formData.torre}
                onChange={(e) =>
                  setFormData({ ...formData, torre: e.target.value })
                }
                required
                margin="normal"
              >
                <MenuItem value="Acqua">Acqua</MenuItem>
                <MenuItem value="Natura">Natura</MenuItem>
              </Select>
              <Select
                label="Apartamento"
                fullWidth
                value={formData.apartamento}
                onChange={(e) =>
                  setFormData({ ...formData, apartamento: e.target.value })
                }
                required
                margin="normal"
              >
                {apartamentos.map((apart) => (
                  <MenuItem key={apart} value={apart}>
                    {apart}
                  </MenuItem>
                ))}
              </Select>

              <MUIButton
                onClick={handleNext}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Próximo
              </MUIButton>
              <MUIButton
                onClick={handleBack}
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{ mt: 2 }}
              >
                Voltar
              </MUIButton>
            </>
          )}

          {activeStep === 3 && (
            <>
              <SectionTitle>Finalizar Compra</SectionTitle>
              <p>
                <strong>Nome:</strong> {formData.nome}
              </p>
              <p>
                <strong>Email:</strong> {formData.email}
              </p>
              <p>
                <strong>CPF:</strong> {formData.cpf}
              </p>
              <p>
                <strong>Apartamento:</strong> {formData.apartamento}
              </p>
              <p>
                <strong>Torre:</strong> {formData.torre}
              </p>
              <p>
                <strong>Mesas Selecionadas:</strong>{" "}
                {selectedMesas.map((mesa) => mesa.numero).join(", ")}
              </p>
              <p>
                <strong>Total:</strong> R$ {valorTotal.toFixed(2)}
              </p>

              {/* Seleção de Formas de Pagamento com Ícones */}
              <FormControl component="fieldset">
                <FormLabel component="legend">Forma de Pagamento</FormLabel>
                <RadioGroup
                  value={formaPagamento}
                  onChange={(e) => {
                    setFormaPagamento(e.target.value);
                    calcularValorTotal(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Pix"
                    control={<Radio />}
                    label={
                      <>
                        <PixIcon /> Pix
                      </>
                    }
                  />
                  <FormControlLabel
                    value="Cartão"
                    control={<Radio />}
                    label={
                      <>
                        <CreditCardIcon /> Cartão
                      </>
                    }
                  />
                  <FormControlLabel
                    value="Dinheiro"
                    control={<Radio />}
                    label={
                      <>
                        <MoneyIcon /> Dinheiro
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {loadingAction ? (
                <CircularProgress></CircularProgress>
              ) : (
                <>
                  <MUIButton
                    fullWidth
                    variant="contained"
                    color="success"
                    sx={{ mt: 2 }}
                    onClick={handleSubmit}
                  >
                    Realizar Pagamento - R$ {valorTotal.toFixed(2)}
                  </MUIButton>
                  {(formaPagamento === "Cartão") &&
                  <p>Pagamento com cartão gera acréscimo de 5% do emissor</p>}
                </>
              )}
              <MUIButton
                onClick={handleBack}
                fullWidth
                variant="outlined"
                color="secondary"
                sx={{ mt: 2 }}
              >
                Voltar
              </MUIButton>
            </>
          )}
        </form>

        {/* Modal de QR Code após pagamento */}
        <Dialog open={modalOpen} onClose={() => hadleCloseModal()}>
          <DialogTitle>Pagamento Gerado com Sucesso!</DialogTitle>
          <DialogContent>
            {qrCodeData && qrCodeData.pagamento && (
              <QRCodeInfo
                qr_code={qrCodeData.pagamento.codigoPix}
                ticket_url={qrCodeData.pagamento.urlPix}
                qr_code_base64={qrCodeData.pagamento.imageqrCode}
                description={qrCodeData.venda.pagamento.description}
                buyerName={formData.nome}
                apartment={formData.apartamento}
                tower={formData.torre}
              />
            )}
          </DialogContent>
        </Dialog>

        {/* Modal para Pagamento com Cartão */}
        <PaymentSuccessModal
          open={paymentModalOpen}
          onClose={() => hadleCloseModal()}
          imageSrc={"/images/mercadopago.png"}
          ticketUrl={urlMp}
        />

        {/* Mensagens de Sucesso e Erro */}
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage("")}
        >
          <Alert onClose={() => setSuccessMessage("")} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </FormContainer>
    </PageContainer>
  );
};

export default FormVenda;
