import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, MenuItem, FormControl, InputLabel, Box, Paper, Grid } from '@mui/material';
import api from '../api/api';
import PdfViewer from '../components/PdfViewer';




// Estilos para a página e componentes
const PageContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020; /* Cor vinho para harmonia */
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase; /* Transformar o texto para maiúsculas */
  letter-spacing: 2px; /* Espaçamento entre letras */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombras sutis para destacar o texto */
  background: linear-gradient(90deg, rgba(128,0,32,1) 0%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  color: transparent; /* Efeito gradiente */
`;

const StyledSelect = styled(Select)`
  margin-bottom: 20px;
  width: 300px;
`;

const TableGrid = styled(Grid)`
  margin-top: 20px;
  gap: 10px;
  justify-content: center;
`;

const TableBox = styled.div`
  padding: 10px;
  width: 100px;
  height: 100px;
  background-color: ${(props) => (props.isAvailable === true ? '#90ee90' : '#f08080')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    background-color: yellow;
  }
`;

const TableInfo = styled.div`
  font-size: 12px;
  color: #333;
`;

const CountdownTimer = styled.div`
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
`;

// Componente principal
const MapaMesas = () => {
  const [eventos, setEventos] = useState([]);
  const [mesas, setMesas] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 300 segundos = 5 minutos

  const pdfFile = '/pdf/CR-layout_Revellion2023.pdf'; // Insira o caminho do PDF

 

  // Função para buscar eventos ao carregar a página
  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await api.get('/api/events');
        setEventos(response.data);
      } catch (error) {
        console.error('Erro ao buscar eventos', error);
      }
    };
    fetchEventos();
  }, []);

  // Função para buscar mesas ao selecionar um evento
  const fetchMesas = async (eventId) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/events/event/${eventId}`);
      setMesas(response.data.mesas);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar as mesas', error);
      setLoading(false);
    }
  };

  // Função para atualizar as mesas automaticamente a cada 5 minutos
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedEvent) {
        fetchMesas(selectedEvent);
      }
      setTimeLeft(300); // Reinicia o contador
    }, 300000); // 5 minutos em milissegundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [selectedEvent]);

  // Função para carregar o último evento selecionado
  useEffect(() => {
    const lastSelectedEvent = localStorage.getItem('selectedEvent');
    if (lastSelectedEvent) {
      setSelectedEvent(lastSelectedEvent);
      fetchMesas(lastSelectedEvent);
    }
  }, []);

  // Handle da mudança de evento selecionado
  const handleEventChange = (event) => {
    const eventId = event.target.value;
    setSelectedEvent(eventId);
    localStorage.setItem('selectedEvent', eventId); // Salva o evento no localStorage
    if (eventId) {
      fetchMesas(eventId);
    } else {
      setMesas([]);
    }
  };

  // Função para atualizar o contador regressivo
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(300); // Reinicia o contador quando chegar a zero
      if (selectedEvent) {
        fetchMesas(selectedEvent); // Atualiza as mesas ao final do contador
      }
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timerId); // Limpa o intervalo quando o componente é desmontado
  }, [timeLeft, selectedEvent]);

  // Função para formatar o tempo restante em minutos e segundos
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <PageContainer>
      <Title>Mapa de Mesas do Evento</Title>

      <CountdownTimer>
        Atualizando em: {formatTime(timeLeft)}
      </CountdownTimer>

      <FormControl variant="outlined">
        <InputLabel id="event-select-label">Selecione um Evento</InputLabel>
        <StyledSelect
          labelId="event-select-label"
          value={selectedEvent}
          onChange={handleEventChange}
          label="Selecione um Evento"
        >
          <MenuItem value="">
            <em>Nenhum</em>
          </MenuItem>
          {eventos.map((evento) => (
            <MenuItem key={evento._id} value={evento._id}>
              {evento.nome}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>

      {loading && <p>Carregando mesas...</p>}

      {!loading && mesas.length > 0 && (
        <TableGrid container spacing={2}>
          {mesas.map((mesa) => (
            <Grid item key={mesa._id}>
              <TableBox isAvailable={mesa.flagDisponivel}>
                <TableInfo>
                  <strong>Mesa {mesa.numero}</strong>
                  {mesa.user ? (
                    <>
                      <div>Apt: {mesa.user.apartamento}</div>
                      <div>Torre: {mesa.user.torre}</div>
                    </>
                  ) : (
                    <div>Disponível</div>
                  )}
                </TableInfo>
              </TableBox>
            </Grid>
          ))}
        </TableGrid>
      )}

{/* <div>
      <h1>Visualizador de PDF</h1>
      <PdfViewer file={pdfFile} />
    </div> */}
    </PageContainer>
  );
};

export default MapaMesas;
