import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress, Snackbar, Alert } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import api from "../api/api";

// Registrando os elementos do Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const PageContainer = styled.div`
  padding: 20px;
`;

const TitlePage = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, rgba(128, 0, 32, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  font-size: 18px;
  color: #333;
`;

const StatContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: bold;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const response = await api.get("/api/vendas/dashboard");
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage("Erro ao carregar dados do dashboard");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!dashboardData) {
    return null;
  }

  const paymentMethodData = {
    labels: ["Pix", "Cartão", "Dinheiro"],
    datasets: [
      {
        data: [
          dashboardData.percentualFormaPagamento.pix,
          dashboardData.percentualFormaPagamento.cartao,
          dashboardData.percentualFormaPagamento.dinheiro,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const paymentStatusData = {
    labels: ["Pendente", "Pago", "Recusado", "Cancelado", "Create"],
    datasets: [
      {
        data: [
          dashboardData.percentualStatusPagamento.pendente,
          dashboardData.percentualStatusPagamento.pago,
          dashboardData.percentualStatusPagamento.recusado,
          dashboardData.percentualStatusPagamento.cancelado,
          dashboardData.percentualStatusPagamento.create,
        ],
        backgroundColor: ["#FFCE56", "#4BC0C0", "#FF6384", "#FF9F40", "#B39DDB"],
        hoverBackgroundColor: ["#FFCE56", "#4BC0C0", "#FF6384", "#FF9F40", "#B39DDB"],
      },
    ],
  };

  const statusMesas = {
    labels: ["Disponiveis", "Vendidas"],
    datasets: [
      {
        data: [
          dashboardData.mesasDisponiveis,
          dashboardData.mesasVendidas,
          
        ],
        backgroundColor: ["#FFCE56", "#4BC0C0"],
        hoverBackgroundColor: ["#FFCE56", "#4BC0C0"],
      },
    ],
  };

  return (
    <PageContainer>
      <TitlePage>Dashboard de Vendas</TitlePage>

      <ChartContainer>
        <div>
          <h2>Forma de Pagamento</h2>
          <Pie data={paymentMethodData} />
        </div>
        <div>
          <h2>Status do Pagamento</h2>
          <Pie data={paymentStatusData} />
        </div>
        <div>
          <h2>Status Mesas</h2>
          <Pie data={statusMesas} />
        </div>
      </ChartContainer>

      <CardGrid>
        <Card>
          <h3>Valor Total Pago</h3>
          <p>R$ {dashboardData.valorTotalPago.toFixed(2)}</p>
        </Card>
        <Card>
          <h3>Valor Total Comissão a Pagar</h3>
          <p>R$ {dashboardData.valorTotalComissao.toFixed(2)}</p>
        </Card>
        <Card>
          <h3>Valor cortesia</h3>
          <p>R$ {dashboardData.valorTotalCortesia.toFixed(2)}</p>
        </Card>
        <Card>
          <h3>Mesas Vendidas / Reservadas</h3>
          <p>{dashboardData.mesasVendidas}</p>
        </Card>
        <Card>
          <h3>Mesas Disponíveis</h3>
          <p>{dashboardData.mesasDisponiveis}</p>
        </Card>
        <Card>
          <h3>Mesas Vendidas Não Pagas</h3>
          <p>{dashboardData.mesasVendidasNaoPagas}</p>
        </Card>
      </CardGrid>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default Dashboard;
