import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colors.footerBackground};
  padding: 2px;
  text-align: center;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
`;

const Footer = () => (
  <FooterContainer>
    <p>&copy; 2024 fjmj system. All rights reserved.</p>
  </FooterContainer>
);

export default Footer;
