const theme = {
    colors: {
      primary: '#3498db',
      secondary: '#2ecc71',
      background: '#f4f4f4',
      text: '#333',
      cardBackground: '#fff',
      footerBackground: '#222',
    },
  };
  
  export default theme;
  