import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  width: 250px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: 	#FAF0E6;

  }
`;

const EventCard = ({ event, onClick }) => {
  return (
    <Card onClick={onClick}>
      <img src={event.foto} alt={event.nome} width="100%" height="150px" />
      <h3>{event.nome}</h3>
      {/* <p>{event.descricao}</p> */}
      <p><strong>Valor: R$ {event.valor}</strong></p>
    </Card>
  );
};

export default EventCard;
