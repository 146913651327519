import React from 'react';
import styled from 'styled-components';

const Nav = styled.div`
//   background-color: ${(props) => props.theme.colors.primary};
  padding: 2px;
  justify-content: space-between;
   text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020; /* Cor vinho para harmonia */
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase; /* Transformar o texto para maiúsculas */
  letter-spacing: 2px; /* Espaçamento entre letras */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombras sutis para destacar o texto */
  background: linear-gradient(90deg, rgba(128,0,32,1) 0%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  color: transparent; /* Efeito gradiente */
`;

const Brand = styled.h1`
//   color: white;
`;

const Navbar = () => (
  <Title>
    <Brand>Riviera Fest</Brand>
  </Title>
);

export default Navbar;
