import React, { useState, useEffect } from 'react';
import { getEvents, deleteEvent } from '../api/eventService';
import styled from 'styled-components';
import Button from '../components/Button';
import Modal from '../components/Modal';
import { getUserSession } from '../utils/storage';

const AdminContainer = styled.div`
  padding: 40px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const EventCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const AdminEventsPage = () => {
  const [events, setEvents] = useState([]);
  const { user } = getUserSession();

  useEffect(() => {
    async function fetchEvents() {
      const eventsList = await getEvents();
      setEvents(eventsList);
    }
    fetchEvents();
  }, []);

  const handleDelete = async (eventId) => {
    await deleteEvent(eventId);
    setEvents(events.filter(event => event._id !== eventId));
  };

  if (!user || user.perfil !== 'Admin') {
    return <p>Acesso não autorizado.</p>;
  }

  return (
    <AdminContainer>
      <h2>Gerenciar Eventos</h2>
      <Button onClick={() => console.log('Adicionar Evento')}>Adicionar Novo Evento</Button>
      {events.map((event) => (
        <EventCard key={event._id}>
          <h3>{event.nome}</h3>
          <p>{event.descricao}</p>
          <p><strong>Data:</strong> {new Date(event.data).toLocaleDateString()}</p>
          <p><strong>Valor:</strong> R$ {event.valor}</p>
          <p><strong>Valor Individual:</strong> R$ {event.valorIndividual}</p>
          <p><strong>Vendas Liberadas:</strong> {event.vendasLiberada ? 'Sim' : 'Não'}</p>
          <Button onClick={() => console.log('Editar Evento')}>Editar</Button>
          <Button onClick={() => handleDelete(event._id)}>Excluir</Button>
        </EventCard>
      ))}
    </AdminContainer>
  );
};

export default AdminEventsPage;
