export const apartamentos = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "701",
  "702",
  "703",
  "704",
  "705",
  "706",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "901",
  "902",
  "903",
  "904",
  "905",
  "906",
  "1001",
  "1002",
  "1003",
  "1004",
  "1005",
  "1006",
  "1101",
  "1102",
  "1103",
  "1104",
  "1105",
  "1106",
  "1201",
  "1202",
  "1203",
  "1204",
  "1205",
  "1206",
  "1301",
  "1302",
  "1303",
  "1304",
  "1305",
  "1306",
  "1401",
  "1402",
  "1403",
  "1404",
  "1405",
  "1406",
  "1501",
  "1502",
  "1503",
  "1504",
  "1505",
  "1506",
  "1601",
  "1602",
  "1603",
  "1604",
  "1605",
  "1606",
  "1701",
  "1702",
  "1703",
  "1704",
  "1705",
  "1706",
  "1801",
  "1802",
  "1803",
  "1804",
  "1805",
  "1806",
  "1901",
  "1902",
  "1903",
  "1904",
  "1905",
  "1906",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2101",
  "2102",
  "2103",
  "2104",
  "2105",
  "2106",
  "2201",
  "2202",
  "2203",
  "2204",
  "2205",
  "2206",
  "2301",
  "2302",
  "2303",
  "2304",
  "2305",
  "2306",
  "2401",
  "2402",
  "2403",
  "2404",
  "2405",
  "2406",
  "2501",
  "2502",
  "2503",
  "2504",
  "2505",
  "2506",
  "2601",
  "2602",
  "2603",
  "2604",
  "2605",
  "2606",
  "2701",
  "2702",
  "2703",
  "2704",
  "2705",
  "2706",
  "2801",
  "2802",
  "2803",
  "2804",
  "2805",
  "2806",
  "2901",
  "2902",
  "2903",
  "2904",
  "2905",
  "2906",
  "3001",
  "3002",
  "3003",
  "3004",
  "3005",
  "3006",
  "3101",
  "3102",
  "3103",
  "3104",
  "3105",
  "3106",
  "3201",
  "3202",
  "3203",
  "3204",
  "3205",
  "3206",
  "3301",
  "3302",
  "3303",
  "3304",
  "3305",
  "3306",
];
