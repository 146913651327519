import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button'; // Seu componente de botão customizado
import Modal from './Modal'; // Seu componente de modal customizado

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const QrCodeImage = styled.img`
  width: 200px;
  height: 200px;
  margin-top: 10px;
`;

const TicketLink = styled.a`
  margin-top: 20px;
  color: #800020; /* Cor Vinho */
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const CopyButton = styled(Button)`
  margin-top: 20px;
`;

const QRCodeInfo = ({ qr_code, ticket_url, qr_code_base64, description, buyerName, apartment, tower }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCopyQRCode = () => {
    navigator.clipboard.writeText(qr_code);
    alert('QR Code copiado para a área de transferência!');
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Exibir Informações para pagamento</Button>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <InfoContainer>
            <h2>Detalhes da Compra</h2>
            <p><strong>Comprador:</strong> {buyerName}</p>
            <p><strong>Apartamento:</strong> {apartment}</p>
            <p><strong>Torre:</strong> {tower}</p>
            <p><strong>Descrição:</strong> {description}</p>
            <p>{qr_code.slice(0, 30)}....</p>
            <button onClick={handleCopyQRCode}>Copiar ( Codigo de pagamento )</button>            
            <TicketLink href={ticket_url} target="_blank" rel="noopener noreferrer">
              Abrir Ticket
            </TicketLink>

            {qr_code_base64 && (
              <>
                <h3>QR Code PIX</h3>
                <QrCodeImage src={`data:image/png;base64,${qr_code_base64}`} alt="QR Code" />
              </>
            )}
          </InfoContainer>
        </Modal>
      )}
    </>
  );
};

export default QRCodeInfo;
