import axios from "axios";
import { getUserSession } from '../utils/storage';


const getApi = axios.create({
  // baseURL: "http://localhost:3001",
  baseURL: "https://rivierafest-backend.onrender.com",
});

async function post(url, body) {

  await addToken(getApi)
  return getApi.post(url, body)
}

async function get(url, body) {
  await addToken(getApi)
 return getApi.get(url, body)
}

async function put(url, body) {
  await addToken(getApi)
  return getApi.put(url, body)
}

async function remove(url, body) {
  await addToken(getApi)
  return  getApi.delete(url, body)
}

async function addToken(api) {
 const { token } =  getUserSession();
  if (token && token !== "\"\"") {
    console.log("token adicionado")

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return api
  }else{
    console.log("não existe token")
  }
  return api;
}


export default ({getApi,post, get,remove,put});
