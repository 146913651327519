import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { confirmResetPassword } from "../api/authService";
import Loader from "../components/Loader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const DivLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  margin-top: 20px;
  object-fit: contain;
`;

const Form = styled.form`
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  text-align: center;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #2980b9;
  }
`;

const Message = styled.p`
  text-align: center;
  color: ${(props) => (props.error ? "red" : "green")};
  margin-top: 10px;
`;

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("As senhas não coincidem");
      return;
    }

    const senhaRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!newPassword.match(senhaRegex)) {
      setError(
        "Senha deve conter 8 caracteres, incluindo letras maiúsculas, minúsculas e números. Exemplo: Abc123mV"
      );
      return;
    }

    try {
      setLoading(true);
      const response = await confirmResetPassword(token, newPassword);
      setLoading(false);
      setSuccess(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setLoading(false);
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        setError(error.response.data.message);
      } else if (error.response && error.response.status === 500) {
        setError(error.response.data.error);
      } else {
        setError("Erro Inesperado! Contate o suporte! " + error);
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <DivLogo>
          <img
            style={{ opacity: "80%" }}
            src="/images/rivasFest.png"
            alt="RFC"
          />
        </DivLogo>
        <Title>Redefinir Senha</Title>
        <InputGroup>
          <Label>Nova Senha</Label>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Confirmar Nova Senha</Label>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </InputGroup>
        {loading && <Loader />}
        {error && <Message error>{error}</Message>}
        {success && <Message>{success}</Message>}
        <Button type="submit" disabled={loading}>
          Redefinir Senha
        </Button>
      </Form>
    </Container>
  );
};

export default ResetPassword;
