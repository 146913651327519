import api from './api';

export const login = async (credentials) => {
  try {
    const response = await api.post('/api/auth/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Erro no login:', error);
    return error;
  }
};

export const register = async (data) => {
  try {
    const response = await api.post('/api/auth/register', data);
    return response;
  } catch (error) {
    console.error('Erro no cadastro:', error);
    return error;
  }
};

export const requestResetPassword = async (email) => {
    try {
        const response =   await api.post(`api/auth/resetpassrequest`, {
            email
          });

          return response
    } catch (error) {
      console.error('Erro no reset de senha:', error);
      throw error;
    }
  };

export const confirmResetPassword = async (token, newPassword) => {
    try {
        const response =   await api.post(`api/auth/confirmresetpassword`, {
            token,
            newPassword,
          });

          return response
    } catch (error) {
      console.error('Erro no cadastro:', error);
      throw error;
    }
  };
