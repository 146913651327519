import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 50px;
  background-color: ${(props) => props.theme.colors.background};
  text-align: center;
`;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  font-size: 32px;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.text};
  font-size: 18px;
`;

const Image = styled.img`
  width: 50%;
  height: 50%;
  border-radius: 8px;
  margin-top: 20px;
  object-fit: contain;

`;

const LandingPage = () => (
  <Section>
    <Heading>Aqui você encontra todos os eventos!</Heading>
    <Paragraph>
      Aqui você consegue informações sobre o eventos do condominio riviera criado para moradores e seus convidados.
     
    </Paragraph>
    <Image src="images/rivasFest.png" alt="Banner do evento" />
    <Paragraph>
      Se você é morador encontre seu evento e garanta seu ingresso!
    </Paragraph>
  </Section>
);

export default LandingPage;
