import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api/api'; // Importe seu serviço de API

// Estilos
const PageContainer = styled.div`
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
`;

const CardContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 40px;
  max-width: 500px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #800020;
  margin-bottom: 20px;
`;

const PaymentInfo = styled.div`
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
`;

const InfoItem = styled.p`
  margin: 5px 0;
  color: #333;
  strong {
    color: #800020;
  }
`;

const BackButton = styled.button`
  background-color: #800020;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #aa2233;
  }
`;

const StatusVenda = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extrair os parâmetros da query string
  const queryParams = new URLSearchParams(location.search);
  const vendaId = queryParams.get('id');

  const [venda, setVenda] = useState(null); // Estado para armazenar os dados da venda
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro

  // Função para buscar a venda pela API
  const fetchVenda = async () => {
    try {
      const response = await api.get(`/api/vendas/${vendaId}`); // Chamada da API usando o id da venda da query string
      setVenda(response.data); // Armazenar os dados da venda no estado
      setLoading(false);
    } catch (err) {
      setError('Erro ao carregar os dados da venda');
      setLoading(false);
    }
  };

  // Chamar a função fetchVenda ao carregar o componente
  useEffect(() => {
    fetchVenda();
  }, [vendaId]);

  // Função para determinar o título com base no status
  const getStatusTitle = () => {
    if (!venda) return '';
    switch (venda.statusPagamento) {
      case 'Pago':
        return 'Pagamento Aprovado';
      case 'Pendente':
        return 'Pagamento Pendente';
      case 'Recusado':
        return 'Pagamento Recusado';
      case 'Cancelado':
        return 'Pagamento Cancelado';
      case 'Processamento':
        return 'Pagamento em Processamento';
      default:
        return 'Status Desconhecido';
    }
  };

  // Mostrar a página de carregamento ou erro se for o caso
  if (loading) {
    return <PageContainer>Carregando...</PageContainer>;
  }

  if (error) {
    return <PageContainer>{error}</PageContainer>;
  }

  // Caso tenha carregado a venda com sucesso
  return (
    <PageContainer>
      <CardContainer>
        <Title>{getStatusTitle()}</Title>

        {venda.pagamento && (

        <PaymentInfo>
          <InfoItem><strong>ID do Pagamento:</strong> {venda.pagamento.id}</InfoItem>
          <InfoItem><strong>Status:</strong> {venda.statusPagamento}</InfoItem>
          <InfoItem><strong>Ordem de Venda no Mercado Pago:</strong> {venda.pagamento.merchant_order_id}</InfoItem>
          <InfoItem><strong>Venda:</strong> {venda._id}</InfoItem>
        </PaymentInfo>

        )}

        <BackButton onClick={() => navigate('/')}>
          Voltar para Home
        </BackButton>
      </CardContainer>
    </PageContainer>
  );
};

export default StatusVenda;
