import React, { useEffect, useState } from 'react';
import EventCard from '../components/EventCard';
import LandingPage from '../components/LandingPage';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { getEvents } from '../api/eventService';
import theme from '../styles/Theme';


const Container = styled.div`
  padding: 20px;
  display:flex;
  flex-direction:row;
`;

const HomePage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      const eventList = await getEvents();
      setEvents(eventList);
    }
    fetchEvents();
  }, []);

  return (
    <>
      <Navbar props = {theme}  />
      <LandingPage />
      <Container>
        {events.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
