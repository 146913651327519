import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { register } from "../api/authService";
import Button from "../components/Button";
import Input from "../components/Input";
import { saveUserSession } from "../utils/storage";
import { apartamentos } from "../utils/apartamentos";

import { Select, MenuItem } from "@mui/material";

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const RegisterForm = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #800020; /* Cor vinho para harmonia */
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase; /* Transformar o texto para maiúsculas */
  letter-spacing: 2px; /* Espaçamento entre letras */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombras sutis para destacar o texto */
  background: linear-gradient(90deg, rgba(128,0,32,1) 0%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  color: transparent; /* Efeito gradiente */
`;

const FeedbackMessage = styled.div`
  color: ${(props) => (props.success ? "green" : "red")};
  margin-top: 10px;
  font-size: 14px;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #800020;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    cpf: "",
    password: "",
    apartamento: "",
    torre: "Acqua",
    perfil: "Morador",
  });

  const [feedback, setFeedback] = useState({ message: "", success: false });
  const [loading, setLoading] = useState(false); // Estado para o loader
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Verificar se todos os campos estão preenchidos
    if (
      !formData.name ||
      !formData.email ||
      !formData.cpf ||
      !formData.password ||
      !formData.apartamento ||
      !formData.torre
    ) {
      setFeedback({
        message: "Por favor, preencha todos os campos obrigatórios.",
        success: false,
      });
      return;
    }

    setLoading(true); // Ativa o loader

    try {
      const retorno = await register(formData);
      console.log(retorno);

      if (retorno && retorno.status === 200 && retorno.data._id) {
        setFeedback({
          message: "Cadastro realizado com sucesso!",
          success: true,
        });
        setLoading(false); // Desativa o loader
        saveUserSession(retorno.data); // Salva token, nome e email no localStorage

        setTimeout(() => navigate("/home"), 2000); // Redireciona para a home após 2 segundos
      } else {
        console.log("Deu erro!");

        console.log(retorno.response.data.message);
        setFeedback({
          message:
            retorno.response.data.message ||
            "Erro no cadastro, tente novamente.",
          success: false,
        });
        setLoading(false); // Desativa o loader
      }
    } catch (error) {
      console.log(error);
      setFeedback({
        message: "Erro ao realizar o cadastro. Tente novamente.",
        success: false,
      });
      setLoading(false); // Desativa o loader em caso de erro
    }
  };

  return (
    <RegisterContainer>
      <RegisterForm>
        <Title>Cadastro</Title>
        <form onSubmit={handleRegister}>
          <Input
            placeholder="Nome"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
          <Input
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            required
          />
          <Input
            type="password"
            placeholder="Senha"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            required
          />
          <Input
            placeholder="CPF"
            value={formData.cpf}
            type="number"

            onChange={(e) => setFormData({ ...formData, cpf: e.target.value })}
            required
          />
            <Input
            placeholder="Telefone"
            value={formData.telefone}
            type="number"

            onChange={(e) => setFormData({ ...formData, telefone: e.target.value })}
            required
          />
          
          <label>Apartamento</label>
          <Select
            label="Apartamento"
            fullWidth
            value={formData.apartamento}
            onChange={(e) =>
              setFormData({ ...formData, apartamento: e.target.value })
            }
            required
            margin="normal"
          >
            {apartamentos.map((apart) => (
              <MenuItem key={apart} value={apart}>
                {apart}
              </MenuItem>
            ))}
          </Select>
          
          <Select
                label="Torre"
                fullWidth
                value={formData.torre}
                onChange={(e) =>
                  setFormData({ ...formData, torre: e.target.value })
                }
                required
                margin="normal"
              >
                <MenuItem value="Acqua">Acqua</MenuItem>
                <MenuItem value="Natura">Natura</MenuItem>
              </Select>

          {/* Exibir o loader quando estiver carregando */}
          {loading ? (
            <Loader />
          ) : (
            <Button onClick={(e) => handleRegister(e)} disabled={loading}>
              Cadastrar
            </Button>
          )}
        </form>

        {/* Exibe mensagens de erro ou sucesso */}
        {feedback.message && (
          <FeedbackMessage success={feedback.success}>
            {feedback.message}
          </FeedbackMessage>
        )}
      </RegisterForm>
    </RegisterContainer>
  );
};

export default RegisterPage;
