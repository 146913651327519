import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { getUserSession } from "./utils/storage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AdminEventsPage from "./pages/AdminEventsPage";
import HomePage from "./pages/HomePage";
import Vendas from "./pages/Venda/RealizarVendaAdmin";
import ListaVenda from "./pages/Venda/ListaVenda";
import StatusVenda from "./pages/Venda/StatusVenda";
import MapaMesas from "./pages/MapaMesas";
import ResetPassword from "./pages/ResetPassword";
import MinhasCompras from "./pages/MinhasCompras";
import dashboardVendas from "./pages/dashboardVendas";
import UsersPage from "./pages/UsersPage";

// Rota pública
const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} element={<Component />} />;
};

// Rota privada (somente para usuários logados)
const PrivateRoute = ({ element: Component, ...rest }) => {
  const { token } = getUserSession();
  return token ? <Component /> : <Navigate to="/login" />;
};

// Rota privada para Admin
const AdminRoute = ({ element: Component, ...rest }) => {
  const { user } = getUserSession();
  return user && user.perfil === "Admin" ? (
    <Component />
  ) : (
    <Navigate to="/login" />
  );
};

// Configuração de rotas
const AppRoutes = () => {
  return (
    // <Router>
    <Routes>
      {/* Rotas públicas */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/home" element={<HomePage></HomePage>} />
      <Route path="/statusVenda" element={<StatusVenda></StatusVenda>} />
      <Route
        path="/reset-password/:token"
        element={<ResetPassword></ResetPassword>}
      />
      {/* Rotas privadas */}
      <Route
        path="/mapa-Mesas"
        element={<PrivateRoute element={MapaMesas} />}
      />
      <Route
        path="/minhas-compras"
        element={<PrivateRoute element={MinhasCompras} />}
      />
      {/* Rotas exclusivas para Admin */}
      <Route
        path="/admin-events"
        element={<AdminRoute element={AdminEventsPage} />}
      />
      <Route
        path="/realizar-vendas"
        element={<AdminRoute element={Vendas} />}
      />
         <Route
        path="/dashboard"
        element={<AdminRoute element={dashboardVendas} />}
      />
      <Route
        path="/lista-vendas"
        element={<AdminRoute element={ListaVenda} />}
      />
      ListaVenda
      <Route
        path="/lista-usuarios"
        element={<AdminRoute element={UsersPage} />}
      />
      Usuários
      {/* Redirecionamento padrão */}
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
    // </Router>
  );
};

export default AppRoutes;
