import React, { useState } from "react";
import styled from "styled-components";
import { login, requestResetPassword } from "./../api/authService";
import { saveUserSession } from "../utils/storage";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Input from "../components/Input";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const LoginForm = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const Title = styled.h2`
  color: #800020; /* Vinho */
  margin-bottom: 20px;
`;

const Link = styled.a`
  color: #800020;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const [severity, setSeverity] = useState("success");

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const retorno = await login({ email, password });

    if (retorno.token != null) {
      saveUserSession(retorno); // Salva token, nome e email no localStorage
      //   window.location.href = '/admin-events';  // Redireciona para página de administração
      navigate("home");
    } else {
      setLoading(false);
    console.log(retorno)
      alert(retorno.response.data.message);
    }
  };

  const handleResetPassword = async (e) => {
    try {
      setLoading(true);
      const response = await requestResetPassword(email);

      if (response.status == 200) {
        setModalOpen(false);
        setMessage("E-mail para redefinição enviado verifique seu email.");
        setSeverity("success");
        setOpenMessage(true);
        setLoading(false);
      } else {
        setMessage(response.data.error);
        setSeverity("error");
        setOpenMessage(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao logar", error);
      setMessage(
        error.response.data.error
          ? error.response.data.error
          : "Ocorreu um erro"
      );
      setSeverity("error");
      setOpenMessage(true);
      setLoading(false)

    }
  };
  const handleCloseSuccess = () => {
    setOpenMessage(false);
  };

  return (
    <LoginContainer>
      <Snackbar
        open={openMessage}
        onClose={handleCloseSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </MuiAlert>
      </Snackbar>
      <LoginForm>
        <Title>Login</Title>
        <form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button onClick={(e) => handleLogin(e)}>
            {loading ? "Entrando..." : "Entrar"}
          </Button>
        </form>
        <Link onClick={() => setModalOpen(true)}>Esqueci a senha</Link>
      </LoginForm>

      {/* Modal para esqueci a senha */}
      {isModalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <h3>Recuperar Senha</h3>
          <Input type="email" placeholder="Digite seu email" />
          <Button onClick={(e) => handleResetPassword(e)}>
            {loading ? "Solicitando..." : "Solicitar"}
          </Button>
        </Modal>
      )}
    </LoginContainer>
  );
};

export default LoginPage;
