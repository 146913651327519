import React from 'react';
import styled from 'styled-components';

const MesaContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => 
    !props.mesa.flagDisponivel ? 'gray' : props.selecionada ? 'yellow' : 'green'};
  border-radius: 4px;
  cursor: ${props => (props.mesa.flagDisponivel ? 'pointer' : 'not-allowed')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Mesa = ({ mesa, onClick, selecionada }) => {
  return (
    <MesaContainer mesa={mesa} onClick={onClick} selecionada={selecionada}>
      {mesa.numero}
    </MesaContainer>
  );
};

export default Mesa;
