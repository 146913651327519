import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;

  &:focus {
    border-color: #800020; /* Vinho */
    outline: none;
  }
`;

export default Input;
