export const saveUserSession = (userData) => {
  localStorage.setItem("token", userData.token);
  localStorage.setItem(
    "user",
    JSON.stringify({
      name: userData.name,
      email: userData.email,
      perfil: userData.perfil,
    })
  );
};

export const getUserSession = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  return { token, user };
};

export const clearUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};
