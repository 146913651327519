import api from './api';

// Cadastrar evento
export const createEvent = async (eventData) => {
  try {
    const response = await api.post('/api/events', eventData);
    return response.data;
  } catch (error) {
    console.error('Erro ao cadastrar evento:', error);
    return null;
  }
};

// Editar evento
export const updateEvent = async (eventId, updatedData) => {
  try {
    const response = await api.put(`/api/events/${eventId}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar evento:', error);
    return null;
  }
};

// Buscar todos os eventos
export const getEvents = async () => {
  try {
    const response = await api.get('/api/events');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar eventos:', error);
    return [];
  }
};

// Excluir evento
export const deleteEvent = async (eventId) => {
  try {
    const response = await api.remove(`/api/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao excluir evento:', error);
    return null;
  }
};
