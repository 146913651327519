import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PanToolIcon from '@mui/icons-material/PanTool';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';



// Estilização do componente
const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ZoomButton = styled(IconButton)`
  margin: 0 10px;
`;

const PdfViewer = ({ file }) => {
  const [pageNumber, setPageNumber] = useState(1); // Página inicial
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  return (
    <PdfContainer>
      {/* Controles de navegação e zoom */}
      <ControlsContainer>
        <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
          Página Anterior
        </Button>
        <Button onClick={goToNextPage} disabled={pageNumber >= numPages}>
          Próxima Página
        </Button>
      </ControlsContainer>

      <TransformWrapper
        initialScale={1}
        minScale={0.5}
        maxScale={3}
        centerContent={true}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            {/* Controles de zoom */}
            <ControlsContainer>
              <ZoomButton onClick={zoomIn}>
                <ZoomInIcon />
              </ZoomButton>
              <ZoomButton onClick={zoomOut}>
                <ZoomOutIcon />
              </ZoomButton>
              <ZoomButton onClick={resetTransform}>
                <PanToolIcon />
              </ZoomButton>
            </ControlsContainer>

            {/* Visualizador de PDF */}
            <TransformComponent>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>

      {/* Indicação de número de páginas */}
      <p>
        Página {pageNumber} de {numPages}
      </p>
    </PdfContainer>
  );
};

export default PdfViewer;
